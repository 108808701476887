import $ from 'jquery';
import visitorInfo from './modules/visitorInfo';
import stickPlaceholders from './modules/stickPlaceholders';
import goToTop from './modules/goToTop';
import toggleMobileMenu from './modules/toggleMobileMenu';
import responsiveHandleResize from './modules/responsiveHandleResize';
import checkout from './modules/checkout';
import responsiveSaldochecker from './modules/responsiveSaldochecker';


import drupalBehaviors_srf_selectmenu from './modules/drupalBehaviors/drupalBehaviors_srf_selectmenu';
import drupalBehaviors_formPlaceholders from './modules/drupalBehaviors/drupalBehaviors_formPlaceholders';

$(document).ready(function() {

    // Add site visitor info like his operating system and browser to the body class;
    visitorInfo();

    //set timeout for handling browser resize
    var handleResizeAfterResizeTimeOut;

    $(window).resize(() => {
        clearTimeout(handleResizeAfterResizeTimeOut);
        handleResizeAfterResizeTimeOut = setTimeout(() => {
            responsiveHandleResize();
        }, 300);
    }).trigger('resize');

    // Go to top
    goToTop();

    //Handles click on mobile menu button
    toggleMobileMenu();

    //Checkout handles
    checkout();

})

// jQueryui selectmenu - Duplicates and extends the functionality
// of a native HTML select element to overcome the limitations of the native control.
drupalBehaviors_srf_selectmenu();

// Style form placeholders
stickPlaceholders();

// Style form placeholders
drupalBehaviors_formPlaceholders();

// Responsive height for Saldochecker iframe
responsiveSaldochecker();