import $ from 'jquery';

// Style form placeholders

export default function drupalBehaviors_formPlaceholders() {
  Drupal.behaviors.nxte_form_placeholders = {
    attach: (context, settings) => {
    	if (typeof (Drupal.settings.nxte) != 'undefined' && typeof (Drupal.settings.nxte.placeholder_elements) != 'undefined') {
        jQuery(Drupal.settings.nxte.placeholder_elements).stickyPlaceholders();
      }
    }
  };
}

