import $ from 'jquery';

// Creates and handles click on mobile menu button on small screens

export default function toggleMobileMenu() {

  $('.nav-toggle').click(function() {
      $(this).toggleClass('active');
      $('.region-navigation-xs').slideToggle(250);
      return false;
  });

}
