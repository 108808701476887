import $ from 'jquery';

//Go to top

export default function goToTop() {

  $("#triptych-last-container > a").click(function() {
    $('body,html').animate({
      scrollTop: 0
    }, 800);
    return false;
  });

}
