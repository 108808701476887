import $ from 'jquery';

// jQueryui selectmenu - Duplicates and extends the functionality
// of a native HTML select element to overcome the limitations of the native control.
// The Selectmenu widgets provides a styleable select element replacement. It will
// act as a proxy back to the original select element, controlling its state for form submission or serialization

export default function drupalBehaviors_srf_selectmenu() {
    Drupal.behaviors.srf_selectmenu = {
        attach: function(context) {
            $('#search-restaurant-form select, #search-restaurant-form-with-days select').each(function() {
                if (!$(this).data('amr_processed')) {
                    $(this).selectmenu({
                        width: parseInt($(this).css('width'))
                    });
                }
            });
        }
    };
}
