import $ from 'jquery';

// Making Saldochecker iframe responsive.

export default function responsiveSaldochecker() {
    
    // Setup event listener
    // add an event listener for the message from Saldochecker iframe for new browsers and also for old IEs
    let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen for event
    eventer(messageEvent,function(e) {

    // Check that message being passed is the documentHeight
    if (  (typeof e.data === 'string') && (e.data.indexOf('documentHeight:') > -1) ) {
        
        // Split string from identifier

    // When we receive the new height from the Saldochecker-iframe, we can change the height of the <iframe> element in the parent document.
        let height = e.data.split('documentHeight:')[1];   
            height = parseInt(height) + 50; // add a bit of extra space as buffer

        // Change height of <iframe> element
        document.getElementById('saldoCheckerIframe').height = height + 'px';
    } 
    },false);
   
}
