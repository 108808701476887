import $ from 'jquery';

export default function responsiveHandleResize() {
    /**
     * Do things that should be done after resize
     */

    if ($(window).width() < 746) {
        $('.page-node-3 #block-block-1').prependTo($('.region-content'));
        $('.page-node-3 #block-block-15').insertAfter($('#block-block-1'));
        $('.page-node-3 #block-views-slideshow-block').insertAfter($('#block-block-15'));
        $('.page-node-3 #block-block-14').insertAfter($('#block-views-slideshow-block'));
        $('.page-node-3 #block-system-main').insertAfter($('#block-block-14'));
    }

    $('.views_slideshow_cycle_teaser_section').height($('.views_slideshow_slide').height());

}
